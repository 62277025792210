import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";

import { ShareUnrelatedDateService } from "./../../_services/share-unrelated-date.service";

import { AppConfig } from "./../../../app/app.config";
import { Message } from "src/app/_locale/message";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";

@Component({
  selector: "app-header-menu",
  templateUrl: "./header-menu.component.html",
  styleUrls: ["./header-menu.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class HeaderMenuComponent implements OnInit {
  loggedInUserId: string;
  loggedInUserRoleId: string;
  delegateId: any;
  resultJsonObj: any = {};
  selectedLlink = "demographics";
  childRoute: string;
  coeStatus: any;
  delegationArr: any = [];
  usersList: any = [];
  tempLog: any = [];
  showLoader = false;
  isDelegating: any = 0;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  selectedDelegate: any = {};
  showDelegationOption: any = 0;
  conmapySlug = ConstantHelper.COMPANY_SLUG;
  doctorToDoctorEmailRespondCount: any;
  doctorToDoctorEmailPendingCount: any;
  patientSecondOpinionRespondCount: any;
  patientSecondOpinionPendingCount: any;
  physicianSecondOpinionRespondCount: any;
  physicianSecondOpinionPendingCount: any;
  expertSecondOpinionRespondCount: any;
  expertSecondOpinionPendingCount: any;
  totalPendingCount: any;
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public shareUnrelatedDateService: ShareUnrelatedDateService,
    public sweetAlertHelper: SweetAlertHelper
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserRoleId = localStorage.getItem("loggedInUserRoleId");

    // this.delegateId = localStorage.getItem('delegateId') ? localStorage.getItem('delegateId') : this.loggedInUserId;
    this.isDelegating = localStorage.getItem("isDelegating")
      ? localStorage.getItem("isDelegating")
      : 0;
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.delegateId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.findDelegationsByAdmin();
    this.findUserDetails();
    if (this.isDelegating == 1) {
      this.pendingItems(localStorage.getItem("delegateId"), 4);
      this.pendingItems(localStorage.getItem("delegateId"), 3);
      this.pendingItems(localStorage.getItem("delegateId"), 2);
      this.pendingItems(localStorage.getItem("delegateId"), 1);
    }
    this.doctorToDoctorEmailPendingCount = 0;
    this.doctorToDoctorEmailRespondCount = 0;
    this.patientSecondOpinionRespondCount = 0;
    this.patientSecondOpinionPendingCount = 0;
    this.physicianSecondOpinionRespondCount = 0;
    this.physicianSecondOpinionPendingCount = 0;
    this.expertSecondOpinionRespondCount = 0;
    this.expertSecondOpinionPendingCount = 0;
  }

  ngOnInit() {
    const currentUrl = this.router.url;
    if (
      currentUrl.includes("demographics") ||
      (this.isDelegating == 1 && currentUrl.includes("transfer-log-delegated"))
    ) {
      this.selectedLlink = "demographics";
      this.showDelegationOption = 1;
      sessionStorage.setItem(
        "currentLinkGpt",
        "Patient List + Expert 2nd Opinion"
      );
      sessionStorage.setItem("pageNumber", "12");
    } else if (currentUrl.includes("second-opinion-wf")) {
      this.selectedLlink = "demographics";
    } else if (currentUrl.includes("email2all")) {
      this.selectedLlink = "demographics";
    } else if (currentUrl.includes("upload-doc")) {
      this.selectedLlink = "demographics";
    } else if (currentUrl.includes("doc-review")) {
      this.selectedLlink = "demographics";
    } else if (currentUrl.includes("send-email")) {
      this.selectedLlink = "demographics";
    } else if (currentUrl.includes("email-logs")) {
      this.selectedLlink = "demographics";
    } else if (currentUrl.includes("email-docs")) {
      this.selectedLlink = "demographics";
    } else if (currentUrl.includes("resend-docs")) {
      this.selectedLlink = "demographics";
    } else if (currentUrl.includes("assign-doc-group")) {
      this.selectedLlink = "demographics";
    } else if (currentUrl.includes("invite-patient")) {
      this.selectedLlink = "demographics";
    } else if (currentUrl.includes("email2user")) {
      this.selectedLlink = "demographics";
    } else if (currentUrl.includes("users")) {
      this.selectedLlink = "users";
      sessionStorage.setItem("currentLinkGpt", "Administrators");
      sessionStorage.setItem("pageNumber", "3");
    } else if (currentUrl.includes("add-admin-user")) {
      this.selectedLlink = "users";
    } else if (currentUrl.includes("documents")) {
      this.selectedLlink = "documents";
      sessionStorage.setItem("currentLinkGpt", "Practice Documents");
       sessionStorage.setItem("pageNumber", "9");
    } else if (currentUrl.includes("modify-admin-doc")) {
      this.selectedLlink = "documents";
    } else if (currentUrl.includes("add-admin-doc")) {
      this.selectedLlink = "documents";
    } else if (currentUrl.includes("add-doc-group")) {
      this.selectedLlink = "documents";
    } else if (currentUrl.includes("faq")) {
      this.selectedLlink = "faq";
    } else if (currentUrl.includes("activity-logs")) {
      this.selectedLlink = "activitylogs";
    } else if (currentUrl.includes("ushf")) {
      this.selectedLlink = "ushf";
    } else if (currentUrl.includes("practice-groups")) {
      this.selectedLlink = "practiceGroup";
      sessionStorage.setItem("currentLinkGpt", "Center of Excellence");
       sessionStorage.setItem("pageNumber", "20");
    } else if (currentUrl.includes("practice-groups")) {
      this.selectedLlink = "practiceGroup";
    } else if (currentUrl.includes("on-call-log")) {
      this.selectedLlink = "practiceGroup";
    } else if (currentUrl.includes("practice-sub-specialities")) {
      this.selectedLlink = "practiceGroup";
    } else if (currentUrl.includes("doctor-profile") || currentUrl.includes("admin-profile")) {
      this.selectedLlink = "doctorProfile";
      sessionStorage.setItem("currentLinkGpt", "Review Profile");
      sessionStorage.setItem("pageNumber", "2");
    } else if (currentUrl.includes("transfer-log")) {
      this.showDelegationOption = 1
      this.selectedLlink = "transferLogs";
      sessionStorage.setItem("currentLinkGpt", "Email & Transfer Logs");
      sessionStorage.setItem("pageNumber", "23");
    } else if (currentUrl.includes("transfer-reports")) {
      this.selectedLlink = "transferReports";
      sessionStorage.setItem("currentLinkGpt", "Transfer Report");
      sessionStorage.setItem("pageNumber", "26");
    } else if (currentUrl.includes("reply-d2d")) {
      this.selectedLlink = "transferLogs";
    } else if (currentUrl.includes("delegation-log")) {
      this.selectedLlink = "aar";
      sessionStorage.setItem("currentLinkGpt", "Admin Activity Report");
      sessionStorage.setItem("pageNumber", "27");
    } else if (currentUrl.includes("invitation-log")) {
      this.selectedLlink = "users";
    } else if (currentUrl.includes("contact-us")) {
      this.selectedLlink = "";
    } else if (currentUrl.includes("change-password")) {
      this.selectedLlink = "";
    } else if (currentUrl.includes("edit-admin-user")) {
      this.selectedLlink = "users";
    } else if (currentUrl.includes("doctor/dashboard")) {
      sessionStorage.clear();
    } else if (currentUrl.includes("ahsgpt")) {
      sessionStorage.clear()      
      this.selectedLlink = "AhsAiGpt";
    }  else {
      // this.selectedLlink = "home";
    }

    $(".menu-header").click(function () {
      $(".admin-menu").stop().slideToggle(300);
      // console.log("ckeick");
    });
  }

  async ngAfterViewInit() {
    if (this.showDelegationOption == 1 && this.isDelegating == 1 && this.loggedInUserRoleId == '1') {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.onBehalfOfPhysician.workingOnBehlaf
      );
    }
  }

  goToPhysician2ndOpinioLog() {
    this.resetDelegation();
    this.router.navigate([
      "/" + this.childRoute + "/transfer-log/" + this.loggedInUserId + "/1",
    ]);
  }
  goToDashboard() {
    this.resetDelegation();
    this.router.navigate([
      "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
    ]);
  }

  goToAdmins() {
    this.resetDelegation();
    this.router.navigate([
      "/" + this.childRoute + "/users/" + this.loggedInUserId,
    ]);
  }

  goToDemographicForms() {
    this.router.navigate([
      "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
    ]);
  }

  goToPracticeGroup() {
    this.resetDelegation();
    this.router.navigate([
      "/" + this.childRoute + "/practice-groups/" + this.loggedInUserId,
    ]);
  }

  goToUHF() {
    this.router.navigate([
      "/" + this.childRoute + "/ushf/" + this.loggedInUserId,
    ]);
  }

  goToFaqs() {
    this.resetDelegation();
    this.router.navigate([
      "/" + this.childRoute + "/faq/" + this.loggedInUserId,
    ]);
  }

  goToDocuments() {
    this.resetDelegation();
    this.router.navigate([
      "/" + this.childRoute + "/documents/" + this.loggedInUserId,
    ]);
  }

  goToLogs() {
    this.router.navigate([
      "/" + this.childRoute + "/activity-logs/" + this.loggedInUserId,
    ]);
  }

  goToDocGroups() {
    this.router.navigate([
      "/" + this.childRoute + "/add-doc-group/" + this.loggedInUserId,
    ]);
  }

  goToEmailLogs() {
    this.router.navigate([
      "/" + this.childRoute + "/email-logs/" + this.loggedInUserId,
    ]);
  }

  goToDoctorProfile() {
    if(this.loggedInUserRoleId == '1'){
      this.resetDelegation();
      this.router.navigate([
        "/" + this.childRoute + "/admin-profile/" + this.loggedInUserId,
      ]);
    } else {
      this.resetDelegation();
      this.router.navigate([
        "/" + this.childRoute + "/doctor-profile/" + this.loggedInUserId,
      ]);
    }
  }

  goToTransferReports() {
    this.resetDelegation();
    this.router.navigate([
      "/" + this.childRoute + "/transfer-reports/" + this.loggedInUserId,
    ]);
  }

  goToDelegationLog() {
    this.resetDelegation();
    this.router.navigate([
      "/" + this.childRoute + "/delegation-log/" + this.loggedInUserId,
    ]);
  }

  findUserDetails() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log("userd d", apiResponse);
          this.loggedInUserRoleId =
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId;
          this.coeStatus =
            this.resultJsonObj.commandResult.data.userInfo.coe_status;

          localStorage.setItem(
            "loggedInUserMobile",
            this.resultJsonObj.commandResult.data.userInfo.Mobile
          );
          localStorage.setItem(
            "loggedInUserName",
            this.resultJsonObj.commandResult.data.userInfo.FullName
          );
          localStorage.setItem(
            "loggedInUserEmail",
            this.resultJsonObj.commandResult.data.userInfo.Email
          );
          localStorage.setItem(
            "loggedInUserRoleId",
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId
          );
          localStorage.setItem(
            "loggedInUserComapnyId",
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId
          );
          localStorage.setItem(
            "loggedInUserParticipatingCenter",
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId
          );
          localStorage.setItem(
            "loggedInUserComapny",
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId
          );
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findDelegationsByAdmin() {
    const finalData = {
      adminId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findDelegationsByAdminApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          // console.log(res);
          this.delegationArr = res.commandResult.data.delegations;

          this.delegateId = localStorage.getItem("delegateId")
            ? localStorage.getItem("delegateId")
            : this.loggedInUserId;
          this.isDelegating = localStorage.getItem("isDelegating")
            ? localStorage.getItem("isDelegating")
            : 0;
          if (localStorage.getItem("isDelegating") == "1") {
            this.selectedDelegate = this.delegationArr.find(
              (e) => e.physician_id == localStorage.getItem("delegateId")
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  setdelegateId(userId) {
    if (userId != this.loggedInUserId) {
      this.selectedDelegate = this.delegationArr.find(
        (e) => e.physician_id == userId
      );
      if (this.selectedDelegate) {
        const physicianEmail = this.selectedDelegate.physician_email;
        localStorage.setItem("delegateId", userId);
        localStorage.setItem("isDelegating", "1");
        localStorage.setItem("delegatingPhysicianEmail", physicianEmail);
        this.shareUnrelatedDateService.changePhysicianVal(userId);
        this.shareUnrelatedDateService.changeisDelegating("1");
        this.shareUnrelatedDateService.changePatientsTypeVal("1");
        this.shareUnrelatedDateService.changeUserList(this.usersList);
        this.shareUnrelatedDateService.changeTempUserList(this.tempLog);
        window.location.reload();
      } else {
        console.error(`Physician not found with userId ${userId}`);
      }
    } else {
      this.shareUnrelatedDateService.changeisDelegating("0");
      this.shareUnrelatedDateService.changePatientsTypeVal("2");
      localStorage.removeItem("delegateId");
      localStorage.removeItem("isDelegating");
      window.location.reload();
    }
}


  resetDelegation() {
    localStorage.removeItem("delegateId");
    localStorage.removeItem("isDelegating");
  }

  fnFindPatientsByPhysiciaId(userId) {
    this.usersList = [];
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: userId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getUsersListByPhysicianIdApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj.commandResult.data.demographicUsers);
          if (
            this.resultJsonObj.commandResult.data.demographicUsers.length > 0
          ) {
            this.shareUnrelatedDateService.changePhysicianVal(userId);
            this.shareUnrelatedDateService.changeUserList(
              this.resultJsonObj.commandResult.data.demographicUsers
            );
            this.shareUnrelatedDateService.changeTempUserList(
              this.resultJsonObj.commandResult.data.demographicUsers
            );
          } else {
            this.shareUnrelatedDateService.changePhysicianVal(userId);
            this.shareUnrelatedDateService.changeUserList(this.usersList);
            this.shareUnrelatedDateService.changeTempUserList(this.tempLog);
          }

          window.location.reload();

          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  openPhysicianHelpDocument() {
    window.open(
      "https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Physician_Site_Instructions.pdf",
      "_blank"
    );
  }
  openAdminHelpDocument() {
    window.open(
      "https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Admin_Site_Instructions.pdf",
      "_blank"
    );
  }

  doctorToDoctorEmail() {
    this.router.navigate([
      "/" +
        this.childRoute +
        "/transfer-log-delegated/" +
        this.loggedInUserId +
        "/" +
        4,
    ]);
  }

  patientSecondOpinion() {
    this.router.navigate([
      "/" +
        this.childRoute +
        "/transfer-log-delegated/" +
        this.loggedInUserId +
        "/" +
        3,
    ]);
  }

  physicianSecondOpinion() {
    this.router.navigate([
      "/" +
        this.childRoute +
        "/transfer-log-delegated/" +
        this.loggedInUserId +
        "/" +
        2,
    ]);
  }

  expertSecondOpinion() {
    this.router.navigate([
      "/" +
        this.childRoute +
        "/transfer-log-delegated/" +
        this.loggedInUserId +
        "/" +
        1,
    ]);
  }

  pendingItems(userId, logType) {
    const finalData = {
      userId,
      logType,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findOpinionLogApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          for (let i = 0; i < res.commandResult.data.logs.length; i++) {
            if (logType == 4) {
              if (res.commandResult.data.logs[i].ResponseDateTime === "") {
                this.doctorToDoctorEmailPendingCount++;
              } else {
                this.doctorToDoctorEmailRespondCount++;
              }
            } else if (logType == 3) {
              if (res.commandResult.data.logs[i].ResponseDateTime === "") {
                this.patientSecondOpinionPendingCount++;
              } else {
                this.patientSecondOpinionRespondCount++;
              }
            } else if (logType == 2) {
              if (res.commandResult.data.logs[i].ResponseDateTime === "") {
                this.physicianSecondOpinionPendingCount++;
              } else {
                this.physicianSecondOpinionRespondCount++;
              }
            } else if (logType == 1) {
              if (res.commandResult.data.logs[i].ResponseDateTime === "") {
                this.expertSecondOpinionPendingCount++;
              } else {
                this.expertSecondOpinionRespondCount++;
              }
            }
          }
          this.totalPendingCount =
            +this.doctorToDoctorEmailPendingCount +
            +this.patientSecondOpinionPendingCount +
            +this.physicianSecondOpinionPendingCount +
            +this.expertSecondOpinionPendingCount;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToAhsAiGpt() {
    this.resetDelegation()
    this.router.navigate([
      "/" + this.childRoute + "/ahsgpt/" + this.loggedInUserId,
    ]);
  }
}
