import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpParams, HttpRequest, HttpHeaders} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {AppConfig} from './../../../app/app.config';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';
import {UserService} from './../../_services/user.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-admin-comments',
  templateUrl: './admin-comments.component.html',
  styleUrls: ['./admin-comments.component.scss']
})
export class AdminCommentsComponent implements OnInit {
  resultJsonObj: any = {};

  loggedInUserId: string;
  loggedInUserRoleId: any;
  demographyUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  childRoute: string;
  documentsList: any;
  patientName: any;
  showAddCommentModal = false;
  showReplyModal = false;
  showViewReplyModal = false;
  showActionCommentModal = false;
  httpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  httpOptions = {headers: this.httpHeaders};
  showLoader = false;
  message = Message;
  commentobj: any = {};
  initiatedBy: any;
  admins: any = [];
  userRoleVal: any = 0;
  selectedAdminForComment: any;
  selectedPhysicianForComment: any;
  showAllData = 1;

  commentList: any = [];
  selectedComment: any = {};
  showAddNoteModal = false; 
  dtOptions: DataTables.Settings = {};
  adminCommentsList: boolean = false;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    public userService: UserService,
    public datepipe: DatePipe,
    private location: Location
  ) {
    this.commentobj.adminId = '';
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
    this.demographyUserId = this.activatedRoute.snapshot.paramMap.get('demographyUserId');
    this.initiatedBy = localStorage.getItem('loggedInUserName');
    this.commentobj.datetime = this.datepipe.transform((new Date), 'MM/dd/yyyy h:mm a');
    this.commentobj.reply = '-Check BP at 08:00 AM,\n-give Paracetamol IV';
  }

  ngOnInit() {
    this.findComments();
    this.findAdminList();
    this.findUserDetails(this.demographyUserId);
    this.commentobj.selectedAdminForComment = '';
    this.dtOptions = {
      order: []
    };
  }

  findUserDetails(demographicUserId) {
    const finalData = {
      id: demographicUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientName = this.userService.getUS_FromatName(
            apiResponse.commandResult.data.userInfo.FullName
          );
          this.showLoader = false;
        },
        (err) => {
          // console.error(err);
        }
      );
  }

  async findComments() {
    this.commentList = [];
    const finalData = {
      userId: this.loggedInUserId,
      patientId: this.demographyUserId,
      companySlug: ConstantHelper.COMPANY_SLUG,
    };
    this.showLoader = true;
    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.findComments, finalData, this.httpOptions)
      .subscribe(
        (res: any) => {
          if(res.commandResult.data.comments.length > 0){
            this.commentList = res.commandResult.data.comments;
            this.showLoader = false;
            this.adminCommentsList = true;
          }
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
      this.showLoader = false;
  }

  async findNotes() {
    this.commentList = [];
    const finalData = {
      userId: this.loggedInUserId,
      patientId: this.demographyUserId,
      companySlug: ConstantHelper.COMPANY_SLUG,
    };
    this.showLoader = true;
    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.findNotes, finalData, this.httpOptions)
      .subscribe(
        (res: any) => {
          if(res.commandResult.data.comments.length > 0){
            this.commentList = res.commandResult.data.comments;
            this.showLoader = false;
            this.adminCommentsList = true
          }
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
      this.showLoader = false;
  }


  editComment(commentInfo: any) {
    // Implement the edit logic here using the data in the "comment" parameter
  }


  openAddCommentModal() {
    this.showAddCommentModal = true;
  }

  openReplyModal(commentId) {
    this.selectedComment = this.commentList.find(
      (e) => e.CommentId == commentId
    );
    this.showReplyModal = true;
  }

  openViewReplyModal(commentId) {
    this.selectedComment = this.commentList.find(
      (e) => e.CommentId == commentId
    );
    console.log(this.selectedComment);
    this.showViewReplyModal = true;
  }

  openActionCommentModal() {
    this.showActionCommentModal = true;
  }


  closeReplyModal() {
    this.showReplyModal = false;
  }

  closeViewReplyModal() {
    this.showViewReplyModal = false;
  }

  closeActionCommentModal() {
    this.showActionCommentModal = false;
  }

  findAdminList() {
    this.admins = [];

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
      userRoleVal: 1,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getAdminUsersListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          this.admins = this.resultJsonObj.commandResult.data.adminUsers;
        },
        (err) => {
        }
      );
  }

  showData(val) {
    this.showAllData = val;
    if (val === 2) {
      this.findNotes();
    } else {
      this.findComments();
    }


  }


  async saveComment() {
    if (this.commentobj.comments === undefined || this.commentobj.comments.length <= 0) {
      await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.addCommentForm.commentsRequired);
      return false;
    } else if (this.commentobj.adminId === undefined || this.commentobj.adminId.length <= 0) {
      await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.addCommentForm.adminRequired);
      return false;
    } else {
      const confirmStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.addCommentForm.addConfirm);
      if (confirmStatus) {
        this.showLoader = false;
        const finalData = {
          comments: this.commentobj.comments,
          loggedInUserId: this.loggedInUserId,
          patientId: this.demographyUserId,
          adminId: this.commentobj.adminId,
        };
        this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_saveCommentApi, finalData, this.httpOptions).subscribe(
          async (res: any) => {
            this.showLoader = false;
            await this.sweetAlertHelper.alertPopUp(res.commandResult.message);
            window.location.reload();
          },
          (err) => {
            this.showLoader = false;
            console.error(err);
          }
        );
      }

    }

  }


  async completeAddCommentModal() {

    // this.showAddCommentModal = false;
    if (this.selectedComment.Reply === undefined || this.selectedComment.Reply.length <= 0) {
      await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.addCommentForm.replyRequired);
      return false;
    } else {
      const confirmStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.addCommentForm.addRemarksConfirm);
      if (confirmStatus) {
        this.showLoader = true;
        const finalData = {
          commentId: this.selectedComment.CommentId,
          reply: this.selectedComment.Reply,
          loggedInUserId: this.loggedInUserId
        };
        this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_saveCommentRemarkApi, finalData, this.httpOptions).subscribe(
          async (res: any) => {
            this.showLoader = false;
            await this.sweetAlertHelper.alertPopUp(res.commandResult.message);
            window.location.reload();
          },
          (err) => {
            this.showLoader = false;
            console.error(err);
          }
        );
      }

    }

  }

  async closeAddCommentModal() {
    this.showAddCommentModal = false;
    this.showAddNoteModal = false;
    this.commentobj.comments = "";
  }

  async goToDemographics() {
    this.location.back();
  }

  openAddNoteModal() {
    this.showAddNoteModal = true;
  }

  async saveNote() {
    if (this.commentobj.comments === undefined || this.commentobj.comments.length <= 0) {
      await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.addCommentForm.commentsRequired);
      return false;
    } else if (this.commentobj.adminId === undefined || this.commentobj.adminId.length <= 0) {
      await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.addCommentForm.adminRequired);
      return false;
    } else {
      const confirmStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.addCommentForm.addNoteConfirm);
      if (confirmStatus) {
        this.showLoader = false;
        const finalData = {
          comments: this.commentobj.comments,
          loggedInUserId: this.loggedInUserId,
          patientId: this.demographyUserId,
          adminId: this.commentobj.adminId,
        };
        this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_saveCommentApi, finalData, this.httpOptions).subscribe(
          async (res: any) => {
            this.showLoader = false;
            await this.sweetAlertHelper.alertPopUp('Note added successfully');
            window.location.reload();
          },
          (err) => {
            this.showLoader = false;
            console.error(err);
          }
        );
      }
    }
  }

  openMp3FIle(recordingUrl: string): void {
    if (recordingUrl) {
      window.open(recordingUrl, '_blank');
    } else {
      console.error('Recording URL is not available.');
    }
  }

  openTranscript(TranscriptUrl: string): void {
    if (TranscriptUrl) {
      window.open(TranscriptUrl, '_blank');
    } else {
      console.error('Transcript URL is not available.');
    }
  }

  openSummary(SummaryUrl: string): void {
    if (SummaryUrl) {
      window.open(SummaryUrl, '_blank');
    } else {
      console.error('Summary URL is not available.');
    }
  }


}
