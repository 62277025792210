import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SignaturePad } from "angular2-signaturepad";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";
@Component({
  selector: "app-signature-waiver",
  templateUrl: "./signature-waiver.component.html",
  styleUrls: ["./signature-waiver.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class SignatureWaiverComponent implements OnInit {
  loggedInUserId: string;
  demographicId: string;
  phyicianId: any;
  invitationId: any;
  parentId: any;

  resultJsonObj: any = {};
  clean: string = "0";
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    public sweetAlertHelper: SweetAlertHelper,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.clean = this.activatedRoute.snapshot.paramMap.get("clean");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");

    this.phyicianId = this.activatedRoute.snapshot.paramMap.get("phyicianId");
    this.invitationId =
      this.activatedRoute.snapshot.paramMap.get("invitationId");
    this.parentId = this.activatedRoute.snapshot.paramMap.get("parentId")
      ? this.activatedRoute.snapshot.paramMap.get("parentId")
      : 0;

    this.findUserDetails();
    if (this.clean == "1") {
      this.cleanTempDocs();
    }
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  async saveCanvas() {
    // if (confirm('PLEASE CONFIRM: By clicking \'OK\', you are signing the document and now will be able to review each.'))
    if(this.signaturePad.isEmpty() === true){
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.signatureWaiverComp.saveCanvas1
      );
    }else{
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.signaturewaiverComp.saveCanvas
    );
    {
      const httpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });
      const options = {
        headers: httpHeaders,
      };
      const finalData1 = {
        loggedInUserId: this.loggedInUserId,
        company_slug: ConstantHelper.COMPANY_SLUG,
      };

      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_deactivateUserDocsApi,
          finalData1,
          options
        )
        .subscribe(
          (apiResponseDoc) => {
            const finalData = {
              loggedInUserId: this.loggedInUserId,
              demographicId: this.demographicId,
              signatureBase64: this.signaturePad.toDataURL(),
              company_slug: null,
            };
            this.http
              .post(
                this.appConfig.getLambdaUrl05() +
                  ApiHelper.name_generateSignatureImageApi,
                finalData,
                options
              )
              .subscribe(
                (apiResponse) => {
                  this.resultJsonObj = apiResponse;
                  // console.log(this.resultJsonObj);
                  this.http
                    .post(
                      this.appConfig.getPhpUrl() +
                        ApiHelper.php_generateDemographicPdfApi,
                      finalData,
                      options
                    )
                    .subscribe(
                      (apiDemoResponse: any) => {
                        // console.log(apiDemoResponse);
                        this.http
                          .post(
                            this.appConfig.getPhpUrl() +
                              ApiHelper.php_generateSignatureFilesPdfPhysicianApi,
                            finalData,
                            options
                          )
                          .subscribe(
                            async (apiSignResponse: any) => {
                              // console.log(apiSignResponse);
                              if (
                                this.resultJsonObj.commandResult.status == 1
                              ) {
                                this.router.navigate([
                                  "/documentthoumbs1/" +
                                    this.loggedInUserId +
                                    "/" +
                                    this.demographicId +
                                    "/" +
                                    this.phyicianId +
                                    "/" +
                                    this.invitationId +
                                    "/" +
                                    this.parentId,
                                ]);
                              } else {
                                // alert("oops! error occured, please try later");

                                const alertStatus =
                                  await this.sweetAlertHelper.alertPopUp(
                                    this.message.alertMessages.allotDocgrpComp
                                      .onSubmi1
                                  );
                              }
                            },
                            (err) => {
                              // console.log(err);
                            }
                          );
                      },
                      (err) => {
                        // console.log(err);
                      }
                    );
                },
                (err) => {
                  // console.log(err);
                }
              );
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }
  }

  async clearCanvas() {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.addAcknowd2dDocComp.clearCanvas
    );
    {
      this.signaturePad.clear();
    }
  }
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log("begin drawing");
  }

  editDemographicForm4() {
    this.router.navigate([
      "/edit-demographic-4/" + this.loggedInUserId + "/" + this.demographicId,
    ]);
  }

  goToWaiverDisclaimerForm() {
    this.router.navigate([
      "/waiverdisclaimerform/" + this.loggedInUserId + "/" + this.demographicId,
    ]);
  }

  goToDemographicForm() {
    this.router.navigate(["/demographicsform/" + this.loggedInUserId]);
  }

  async saveCanvasMobile() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: By clicking 'OK', you are signing the document and now will be able to review each."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.signaturewaiverComp.saveCanvas
    );
    {
      const httpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });
      const options = {
        headers: httpHeaders,
      };
      const finalData1 = {
        loggedInUserId: this.loggedInUserId,
        company_slug: ConstantHelper.COMPANY_SLUG,
      };

      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_deactivateUserDocsApi,
          finalData1,
          options
        )
        .subscribe(
          (apiResponseDoc) => {
            const finalData = {
              loggedInUserId: this.loggedInUserId,
              demographicId: this.demographicId,
              signatureBase64: this.signaturePad.toDataURL(),
            };
            this.http
              .post(
                this.appConfig.getLambdaUrl01() +
                  ApiHelper.name_generateSignatureImageApi,
                finalData,
                options
              )
              .subscribe(
                (apiResponse) => {
                  this.resultJsonObj = apiResponse;
                  // console.log(this.resultJsonObj);
                  this.http
                    .post(
                      this.appConfig.getPhpUrl() +
                        ApiHelper.php_generateDemographicPdfApi,
                      finalData,
                      options
                    )
                    .subscribe(
                      (apiDemoResponse: any) => {
                        // console.log(apiDemoResponse);
                        this.http
                          .post(
                            this.appConfig.getPhpUrl() +
                              ApiHelper.php_generateSignatureFilesPdfPhysicianApi,
                            finalData,
                            options
                          )
                          .subscribe(
                            async (apiSignResponse: any) => {
                              // console.log(apiSignResponse);
                              if (
                                this.resultJsonObj.commandResult.status == 1
                              ) {
                                this.router.navigate([
                                  "/documentthoumbs/" +
                                    this.loggedInUserId +
                                    "/" +
                                    this.demographicId,
                                ]);
                              } else {
                                // alert("oops! error occured, please try later");
                                await this.sweetAlertHelper.alertPopUp(
                                  this.message.alertMessages.allotDocgrpComp
                                    .onSubmi1
                                );
                              }
                            },
                            (err) => {
                              // console.log(err);
                            }
                          );
                      },
                      (err) => {
                        // console.log(err);
                      }
                    );
                },
                (err) => {
                  // console.log(err);
                }
              );
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async goToWelcome() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to leave and going back to Home Page."
    //   )
    // )

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  async goToRegisteredDoctors() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to leave and going back to Home Page."
    //   )
    // )

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      let documentIds = JSON.parse(sessionStorage.getItem('documentIds')) || [];        
      documentIds.forEach((id: string) => {          
          sessionStorage.removeItem(id);
        });        
      sessionStorage.removeItem('documentIds');      
        let groupDocIds = JSON.parse(sessionStorage.getItem('groupDocIds')) || [];        
        groupDocIds.forEach((id: string) => {          
          sessionStorage.removeItem(id);
        });        
        sessionStorage.removeItem('groupDocIds');
        sessionStorage.removeItem('practiceDocs');        
      window.open(
        this.userService.parentSiteUrl() +
          "/registered-doctors/" +
          this.loggedInUserId,
        "_self"
      );
    }
  }

  cleanTempDocs() {
    const finalData = {
      id: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_cleanTempDocsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('userd d', apiResponse);
          localStorage.setItem("isUserIdloggedIn", "Y");
          localStorage.setItem(
            "loggedInUserId",
            this.resultJsonObj.commandResult.data.userInfo.RecordId
          );
          localStorage.setItem(
            "loggedInUserName",
            this.resultJsonObj.commandResult.data.userInfo.FullName
          );
          localStorage.setItem(
            "loggedInUserEmail",
            this.resultJsonObj.commandResult.data.userInfo.Email
          );
          localStorage.setItem(
            "loggedInUserRoleId",
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId
          );
          localStorage.setItem("loggedInUserComapnyId", "1");
        },
        (err) => {
          // console.log(err);
        }
      );
  }
}
