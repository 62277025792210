import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';

import {environment} from './../../../environments/environment';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {AppConfig} from './../../../app/app.config';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {UserService} from './../../_services/user.service';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';
import {ChatbotService} from 'src/app/chatbot/chatbot.service';
import * as md5 from 'md5';


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
  currentSite = ConstantHelper.COMPANY_NAME;
  conmapySlug = ConstantHelper.COMPANY_SLUG;

  loggedInUserId: string;
  childRoute: string;
  loggedInUserRoleId: any;
  demographicId: string;
  resultJsonObj: any = {};
  isDisbleLink = true;
  showModificationAlert = false;
  userRole: any;
  coeStatus: any;
  isPrimary: any;
  showLoader = false;
  isChatbotOpen: boolean;
  showContent = false;
  isTemperoryPassword: any;
  cnFieldTextType: boolean = false;
  fieldTextType: boolean = false;
  newPassword: any = '';
  confirmNewPassword: any = '';
  passwordMismatch: boolean = false;
  profileObj: any = {};
  tempPassword: any;
  loggedInUserName: any;

  tempfieldTextType : boolean = false;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  // tslint:disable-next-line: max-line-length
  tooltipText =
    ' 1)Click and selec document set at bottom of demographic page. Click `Accept` to  return to `Home Page`<br>2) Click `Review & Sign` in the dashboard below.<br>3) Click each document icon to preview.<br>4) Download each document to fil in and sign with Acrobat Reader (link provided).<br>5) You can also print, complete, sign, and scan any document to your desktop.<br>6) Returmto Home Page<br>7) Click `Upload Doc` and `choose` each completed codument to upload.<br>8) Return to Home Page<br>9) Click `Review` to confirm upload.';
  passwordForm: any;
  isEdited: boolean = true;
  isDocsSigned: boolean = false;
  is_profile_edited: any;
  secondQues: any;
  firstQues: any;
  Ans_02: any;
  ans01: any;
  QueId_01: any;
  QueId_02: any;
  OldPassword: any;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService,
    private chatbotService: ChatbotService

  ) {
    // this.showLoader = true;

    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
    this.isPrimary = sessionStorage.getItem('isPrimary');
    console.log('is primary key is here:', this.isPrimary);
    // this.loggedInUserName = localStorage.getItem('loggedInUserName')
    // ? localStorage.getItem('loggedInUserName')
    // : localStorage.getItem('loggedInUserEmail');
    // console.log(activatedRoute.snapshot.url); // array of states
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    // console.log(this.childRoute);
    this.userRole = localStorage.getItem('loggedInUserRoleId');
    this.chatbotService.getChatbotState().subscribe((isOpen) => {
      this.isChatbotOpen = isOpen;
    });
  }

  ngOnInit() {
   
    this.findUserDetails().then(() => {
      this.loggedInUserName = sessionStorage.getItem('loggedInUserName');
    });
    sessionStorage.setItem('currentLinkGpt', 'Dashboard');
    sessionStorage.removeItem('dataServiceData');
    this.findUserDetail(this.loggedInUserId);
    this.findFirstSecurityQues();
    this.findSecondSecurityQues();
  }

  hashPassword(password: string): string {
    return md5(password);
  }

  setDocGroupName() {
    const finalData = {
      id: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_setDefaultGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToDemographicForm() {
    localStorage.setItem('showHippaUpdateBtn', '1');
    this.router.navigate(['/demographicsform/' + this.loggedInUserId]);
  }

  goToUSHFForm() {
    this.router.navigate([
      '/' + this.childRoute + '/ushf/' + this.loggedInUserId,
    ]);
  }

  goToDocumentConfirmation() {
    localStorage.setItem('showHippaUpdateBtn', '0');
    this.router.navigate([
      '/hippa-acceptance/' + this.loggedInUserId + '/' + this.demographicId,
    ]);
  }

  goToDocumentReview() {
    this.router.navigate([
      '/doc-review/' + this.loggedInUserId + '/' + this.demographicId,
    ]);
  }

  goToDocumentUpload() {
    this.router.navigate([
      '/upload-doc/' + this.loggedInUserId + '/' + this.demographicId,
    ]);
  }

  goToEmailDoc() {
    this.router.navigate([
      '/send-doc/' + this.loggedInUserId + '/' + this.demographicId,
    ]);
  }

  goToEmailLog() {
    this.router.navigate(['/email-log/' + this.loggedInUserId]);
  }

  async goToProfile() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to go to pofile?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminDashboardComp.goToProfile
    );
    if (alertStatus) {
      this.router.navigate(['/user-profile/' + this.loggedInUserId]);
    }
  }

  async logout() {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure, you want to close your session?")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.headerComp.logout
    );
    if (alertStatus) {
      localStorage.clear();
      window.open(this.userService.parentSiteUrl() + '/', '_self');
    }
  }

  async editDemographicForm4() {
    if (this.demographicId > '0') {
      //   if (
      //     confirm(
      //       "PLEASE CONFIRM: Click OK to review and update your demographic data."
      //     )
      //   )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.adminDashboardComp.editDemographicForm4
      );
      if (alertStatus) {
        this.router.navigate([
          '/edit-demographic-4/' +
          this.loggedInUserId +
          '/' +
          this.demographicId,
        ]);
      }
    } else {
      //   alert("Do you want to review/update your demographic information?");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminDashboardComp.editDemographicForm4
      );
    }
  }

  goToAdmins() {
    this.router.navigate([
      '/' + this.childRoute + '/users/' + this.loggedInUserId,
    ]);
  }

  goToDocuments() {
    this.router.navigate([
      '/' + this.childRoute + '/documents/' + this.loggedInUserId,
    ]);
  }

  goToDemographicForms(isHippa) {
    this.router.navigate([
      '/' +
      this.childRoute +
      '/demographics/' +
      this.loggedInUserId +
      '/' +
      isHippa,
    ]);
  }

  goToLogs() {
    this.router.navigate([
      '/' + this.childRoute + '/activity-logs/' + this.loggedInUserId,
    ]);
  }

  goToFaqs() {
    this.router.navigate([
      '/' + this.childRoute + '/faq/' + this.loggedInUserId,
    ]);
  }

  openPhysicianHelpDocument() {
    window.open(
      'https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Physician_Site_Instructions.pdf',
      '_blank'
    );
  }

  openAdminHelpDocument() {
    window.open(
      'https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Admin_Site_Instructions.pdf',
      '_blank'
    );
  }

  goToDelegationLog() {
    this.router.navigate([
      '/' + this.childRoute + '/delegation-log/' + this.loggedInUserId,
    ]);
  }

  goToTransferReports() {
    this.router.navigate([
      '/' + this.childRoute + '/transfer-reports/' + this.loggedInUserId,
    ]);
  }

  goToPhysician2ndOpinioLog() {
    this.router.navigate([
      '/' + this.childRoute + '/transfer-log/' + this.loggedInUserId + '/1',
    ]);
  }

  goToDoctorEmail() {
    this.router.navigate([
      '/' + this.childRoute + '/second-opinion/' + this.loggedInUserId,
    ]);
  }

  goToPhysicianScheduling() {
    this.router.navigate([
      '/' + this.childRoute + '/physician-scheduling/' + this.loggedInUserId,
    ]);
  }

  goToPracticeGroup() {
    this.router.navigate([
      '/' + this.childRoute + '/practice-groups/' + this.loggedInUserId,
    ]);
  }

  async goToDoctorProfile() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Continue to Review and Update Current Demographics."
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminDashboardComp.goToDoctorProfile
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' + this.childRoute + '/doctor-profile/' + this.loggedInUserId,
      ]);
    // }
  }


  goToAdminProfile()
  {
    this.router.navigate([
      '/' + this.childRoute + '/admin-profile/' + this.loggedInUserId,
    ]);
  }


  // findUserDetails() {
  //   const finalData = {
  //     id: this.loggedInUserId,
  //   };
  //   this.showLoader = true;
  //   this.http
  //     .post(
  //       this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
  //       finalData,
  //       this.httpOptions
  //     )
  //     .subscribe(
  //       (apiResponse) => {
  //         this.resultJsonObj = apiResponse;
  //         // console.log('userd dasf', apiResponse)
  //         this.loggedInUserRoleId =
  //           this.resultJsonObj.commandResult.data.userInfo.UserRoleId;
  //         this.coeStatus =
  //           this.resultJsonObj.commandResult.data.userInfo.coe_status;
  //           this.OldPassword =
  //           this.resultJsonObj.commandResult.data.userInfo.OldPassword;
  //         localStorage.setItem('isUserIdloggedIn', 'Y');
  //         localStorage.setItem(
  //           'loggedInUserId',            
  //           this.resultJsonObj.commandResult.data.userInfo.RecordId
  //         );
  //         localStorage.setItem(
  //           'loggedInUserMobile',
  //           this.resultJsonObj.commandResult.data.userInfo.Mobile
  //         );
  //         localStorage.setItem('loggedInUserName',this.resultJsonObj.commandResult.data.userInfo.FullName);
  //         sessionStorage.setItem('loggedInUserName',this.resultJsonObj.commandResult.data.userInfo.FullName);
  //         localStorage.setItem(
  //           'loggedInUserEmail',
  //           this.resultJsonObj.commandResult.data.userInfo.Email
  //         );
  //         localStorage.setItem(
  //           'loggedInUserRoleId',
  //           this.resultJsonObj.commandResult.data.userInfo.UserRoleId
  //         );
  //         localStorage.setItem(
  //           'loggedInUserComapnyId',
  //           this.resultJsonObj.commandResult.data.userInfo.UserRoleId
  //         );
  //         localStorage.setItem(
  //           'loggedInUserParticipatingCenter',
  //           this.resultJsonObj.commandResult.data.userInfo.UserRoleId
  //         );
  //         localStorage.setItem(
  //           'loggedInUserComapny',
  //           this.resultJsonObj.commandResult.data.userInfo.UserRoleId
  //         );

  //         sessionStorage.setItem('isPrimary', this.resultJsonObj.commandResult.data.userInfo.IsPrimary);

  //         this.isTemperoryPassword = this.resultJsonObj.commandResult.data.userInfo.IsTemperoryPassword

  //         if(this.loggedInUserRoleId == 1){
  //           this.isDocsSigned = this.resultJsonObj.commandResult.data.userInfo.IsProfileCompleted
  //         }

  //         this.showContent = true;
  //       },
  //       (err) => {
  //         // console.log(err);
  //       }
  //     );
  //   this.showLoader = false;
  // }

  findUserDetails(): Promise<void> {
    return new Promise((resolve, reject) => {
      const finalData = { id: this.loggedInUserId };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse) => {
            this.resultJsonObj = apiResponse;
            this.loggedInUserRoleId =
              this.resultJsonObj.commandResult.data.userInfo.UserRoleId;
            this.coeStatus =
              this.resultJsonObj.commandResult.data.userInfo.coe_status;
            this.OldPassword =
              this.resultJsonObj.commandResult.data.userInfo.OldPassword;
            localStorage.setItem('isUserIdloggedIn', 'Y');
            localStorage.setItem(
              'loggedInUserId',
              this.resultJsonObj.commandResult.data.userInfo.RecordId
            );
            localStorage.setItem(
              'loggedInUserMobile',
              this.resultJsonObj.commandResult.data.userInfo.Mobile
            );
            localStorage.setItem('loggedInUserName', this.resultJsonObj.commandResult.data.userInfo.FullName);
            sessionStorage.setItem('loggedInUserName', this.resultJsonObj.commandResult.data.userInfo.FullName);
            localStorage.setItem(
              'loggedInUserEmail',
              this.resultJsonObj.commandResult.data.userInfo.Email
            );
            localStorage.setItem(
              'loggedInUserRoleId',
              this.resultJsonObj.commandResult.data.userInfo.UserRoleId
            );
            localStorage.setItem(
              'loggedInUserComapnyId',
              this.resultJsonObj.commandResult.data.userInfo.UserRoleId
            );
            localStorage.setItem(
              'loggedInUserParticipatingCenter',
              this.resultJsonObj.commandResult.data.userInfo.UserRoleId
            );
            localStorage.setItem(
              'loggedInUserComapny',
              this.resultJsonObj.commandResult.data.userInfo.UserRoleId
            );
  
            sessionStorage.setItem('isPrimary', this.resultJsonObj.commandResult.data.userInfo.IsPrimary);
            this.isTemperoryPassword = this.resultJsonObj.commandResult.data.userInfo.IsTemperoryPassword
  
            if(this.loggedInUserRoleId == 1){
              this.isDocsSigned = this.resultJsonObj.commandResult.data.userInfo.IsProfileCompleted;
            }
            this.showContent = true;
            resolve(); // Resolve the promise when done
          },
          (err) => {
            console.error(err);
            reject(err); // Reject the promise if there is an error
          }
        );
      this.showLoader = false;
    });
  }
  goToContactUs() {
    this.router.navigate([
      '/' + this.childRoute + '/contact-us/' + this.loggedInUserId,
    ]);
  }

  openAhsAiGpt() {
    sessionStorage.setItem("currentLinkGpt", "ahsgpt");
    this.router.navigate(["/" + this.childRoute + "/ahsgpt/" + this.loggedInUserId]);
  }

  openChatbot(): void {
    this.chatbotService.toggleChatbotState();
    this.chatbotService.getChatMessages();
  }

  toggleCnFieldTextType() {
    this.cnFieldTextType = !this.cnFieldTextType;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleTempFieldTextType() {
    this.tempfieldTextType = !this.tempfieldTextType;
  }
  checkPasswords(): void {
    // This will be triggered on every change in either the new password or confirm new password
    this.passwordMismatch = this.newPassword !== this.confirmNewPassword;
  }

  

  async updatePassword() {
    if (this.OldPassword !== this.hashPassword(this.tempPassword)) {
      // alert("Password must match.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        "Temporary Password Doesn't Match"
      );
      return;

    }else{
      const apiResponse = await this.checkPasswordHistory(this.loggedInUserId, this.hashPassword(this.newPassword));
      if (apiResponse.commandResult.status == 1) {
        console.log("Is Password Old: Ays", apiResponse.commandResult.status)
        await this.sweetAlertHelper.alertPopUp("Previous Password cannot be used. Please choose a different password.");  

         // Clear the new password and confirm password fields
         this.newPassword = '';
         this.confirmNewPassword = '';

        return;
      }

    if (!this.passwordMismatch){
      const finalData = {
        userId : this.loggedInUserId,
        newPassword: this.newPassword,
        secQue01: this.QueId_01,
        secQue02: this.QueId_02,
        ans_01: this.ans01,
        ans_02: this.Ans_02

      };
      this.http.post(
          this.appConfig.getLambdaUrl05() + ApiHelper.name_updateNewpassword,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse) => {
            await this.sweetAlertHelper.alertPopUp('Password Updated Successfully! Please Login with New Password');
            if (this.sweetAlertHelper.alertPopUp) {
              localStorage.clear();
              window.open(this.userService.parentSiteUrl() + '/', '_self');
            }
          },
          (err) => {
            console.error(err);
          }
        );
    } else {
    }
  }
  }

  async checkPasswordHistory(userId, password) {
    try {
        const response = await this.http.post(
            this.appConfig.getLambdaUrl05() + ApiHelper.checkPasswordHistory,
            { userId, password },
            this.httpOptions
        ).toPromise();
        return response as any;
    } catch (error) {
        console.error('Error checking password history:', error);
        throw error;
    }
  }

  findUserDetail(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status === 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.showLoader = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findFirstSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 1,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.firstQues = apiResponse.commandResult.data.questionsList;
          console.log(this.firstQues);
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSecondSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 2,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.secondQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  navigateBasedOnRole() {
    if (this.loggedInUserRoleId == 4) {
      this.goToDoctorProfile();
    } else if (this.loggedInUserRoleId == 1) {
      this.goToAdminProfile();
    }
  }

}
