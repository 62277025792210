import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

declare let $: any;

@Component({
  selector: "app-admin-view-d2d-docs",
  templateUrl: "./admin-view-d2d-docs.component.html",
  styleUrls: ["./admin-view-d2d-docs.component.scss"],
})
export class AdminViewD2dDocsComponent implements OnInit {
  childRoute: string;
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  hidePdfPopUp = false;
  demographicUrl: SafeResourceUrl;
  hippaUrl: SafeResourceUrl;
  disclaimerUrl: SafeResourceUrl;
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;
  showDocThumbnails = true;
  ahsPdfUrl: SafeResourceUrl = "";
  documentsLength = false;
  documentsList: any;
  docGroupId: any;
  docGroupTitle: any;
  docSet: any;
  docTyep: any;
  companyDefaultGroupTitle: any;
  companyDefaultGroupId: any;
  activeGroupId: any;
  selectedDocId: any;
  signedGroups: any = [];
  signedGroupsCount: any;
  pId: any = "";
  logId: any = "";
  behaviourId: any = "";
  pageExpire = 0;
  loggedInUserCompany: string;
  selectedFileExt: any = "";
  seleteDocumetUrl: any = "";

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  showLoader = false;
  profileObj: any;
  showDeleteButton = false;
  showHippaUpdateBtn: string;
  patientObj: any;
  patientName: string;
  userOptionId = "0";
  logDetails: any = {};
  maxChars = 500;
  fileDesTxt: string = "";
  showDataVal: any;
  message = Message;
  sendEmail:number=null;
  patientEmail: any;
  patName: any;
  isEmailSentToPatient: number;
  showSendEmailButton: boolean;
  companySlug: boolean;
  id: any;
  patientId: any;
  sendAppointmentEmail: number = 0;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.showSendEmailButton = false;
  }

  ngOnInit() {
    this.behaviourId = this.activatedRoute.snapshot.paramMap.get("behaviourId");
    this.showDataVal = this.activatedRoute.snapshot.paramMap.get("showDataVal");

    this.pId = this.activatedRoute.snapshot.paramMap.get("pId");
    this.showHippaUpdateBtn = localStorage.getItem("showHippaUpdateBtn");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.userOptionId =
      this.activatedRoute.snapshot.paramMap.get("userOptionId");
    this.logId = this.activatedRoute.snapshot.paramMap.get("logId");
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.checkActiveUrl(this.pId, this.logId);
    this.findUserDetails(this.loggedInUserId);
    this.findD2DLogDetail(this.logId);
    // this.getRequestingPhysicianDetails(this.logId);

    if (
      this.userOptionId == "1" ||
      this.userOptionId == "3" ||
      this.userOptionId == "6"
    ) {
      this.findPatientUserInfo(this.pId);
    }
  }

  deleteDoc() {}

  checkActiveUrl(pId, logId) {
    const finalData = {
      pId,
      logId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findD2dDocumentsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('apiResponse=>', apiResponse);
          this.pageExpire = 1;

          if (apiResponse.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList = apiResponse.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showDocument(documentId, documentUrl, docTyep) {
    this.selectedFileExt = this.getFileExtension(documentUrl).toLowerCase();
    this.selectedDocId = documentId;
    this.docTyep = docTyep;
    if (this.selectedFileExt == "doc" || this.selectedFileExt == "docx") {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        "https://docs.google.com/gview?url=" + documentUrl + "&embedded=true"
      );
    } else {
      this.ahsPdfUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
    }
    this.seleteDocumetUrl = documentUrl;
    this.showDeleteButton = true;
  }

  getFileExtension(filename) {
    const extension = filename.split(".").pop();
    return extension;
  }

  async clearViewr() {
    // if (confirm('PLEASE CONFIRM: Are you sure, you want to clear preview?'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminDocReviewComp.clearViewr
    );
    {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
      this.showDeleteButton = false;
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    }
  }

  findUserDetails(userId) {
    const finalData = {
      id: userId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('getUserDetail', apiResponse);
          if (apiResponse.commandResult.status == 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.loggedInUserCompany =
              apiResponse.commandResult.data.userInfo.CompanySlug;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPatientUserInfo(patientUserHashId) {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      profileId: patientUserHashId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl06() +
          ApiHelper.findD2DUserDetailsByUserHash,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientName = apiResponse.commandResult.data.fullName;
          this.patientEmail = apiResponse.commandResult.data.email;
          this.patName=apiResponse.commandResult.data.name;
          this.patientId=apiResponse.commandResult.data.patientId;
          // console.log(this.patientName);
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goBack() {
    this.router.navigate([
      "/" +
        this.childRoute +
        "/transfer-log/" +
        this.loggedInUserId +
        "/" +
        this.showDataVal,
    ]);
  }

  findD2DLogDetail(logId) {
    const finalData = {
      logId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findD2DLogDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.logDetails = res.commandResult.data.log;
          this.isEmailSentToPatient=res.commandResult.data.log.IsEmailSentToPatient== 1 ? 1 : 0;
          // alert(this.isEmailSentToPatient)
          this.updateEmailButtonVisibility(false);
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async reply() {
    if (!this.fileDesTxt) {
      //   alert("Please enter response.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminViewD2dDocComp.reply
      );
      return;
    }else if(this.behaviourId!=7 && this.sendEmail==null){
       //   alert("Please enter Yes or No.");
       const alertStatus = await this.sweetAlertHelper.alertPopUp(
       "Please choose Yes or No.");
      return
    }
    const reqData = {
      logId: this.logDetails.RecordId,
      emailText: this.fileDesTxt,
      sendEmail:this.sendEmail,
      profileId:this.pId,
      sendAppointmentEmail: this.sendAppointmentEmail
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.sendD2dResponse,
        reqData,
        this.httpOptions
      )
      .subscribe(
        async (res: any) => {
          //   alert("Response sent successfully");
          this.showLoader= false;
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.admind2dreviewlogComp.onSubmit
          );
          if (alertStatus)
            this.router.navigate([
              "/" +
                this.childRoute +
                "/transfer-log/" +
                this.loggedInUserId +
                "/" +
                this.showDataVal,
            ]);
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }
  updateEmailButtonVisibility(userInitiated: boolean) {
    // Logic to update button visibility based on isEmailSentToPatient value
    if (userInitiated) {
      this.showSendEmailButton = this.isEmailSentToPatient == 1;
    }
  }

  disableRadioButtons() {
    const yesRadio = document.getElementById('yesResponse') as HTMLInputElement;
    const noRadio = document.getElementById('noResponse') as HTMLInputElement;

    if (yesRadio && noRadio) {
      yesRadio.disabled = true;
      noRadio.disabled = true;
    }
  }

  async sentPatientEmail() {
    const reqData = {
      logId: this.logDetails.RecordId,
      profileId:this.pId
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.referralEmailToPatient,
        reqData,
        this.httpOptions
      )
      .subscribe(
        async (res: any) => {
          this.showLoader= false;
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            "Email Sent to Patient Successfully!"
          );
          this.showLoader = false;
          window.location.reload();
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  getRequestingPhysicianDetails(logId) {
    const finalData = {
      logId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.getRequestingPhysicianDetailsByLogID,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.logDetails = res.commandResult.data;
          this.companySlug=this.logDetails.company_slug;
          this.id=this.logDetails.user_id;

          // alert(this.isEmailSentToPatient)
          this.showLoader = false;
          window.location.reload();
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  updateEmailPreference(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.sendAppointmentEmail = isChecked ? 1 : 0;
  }

  navigateToSecondOpinion() {
    this.router.navigate([ "/" +this.childRoute + "/d2d-email/"+ this.pId +"/"+ this.logId +"/"+ this.loggedInUserId +"/"+ this.userOptionId +"/"+ this.behaviourId +"/"+ this.showDataVal +"/"+this.patientId]); // Replace with your route path
  }


}
