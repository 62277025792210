import { Component, OnInit, ChangeDetectorRef,NgZone } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ApiHelper } from "../../_helpers/api.helper";
import { CommonHelper } from "../../_helpers/common.helper";
import { ConstantHelper } from "../../_helpers/constant.helper";
import { AppConfig } from "../../app.config";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { UserService } from "../../_services/user.service";
import { TextService } from "./textService";
import axios from "axios";
import { Injectable } from "@angular/core";
import { format } from "date-fns";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";
import { Location } from '@angular/common';

import { EMPTY, interval, Subscription } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: "app-admin-practice-dictation",
  templateUrl: "./admin-practice-dictation.component.html",
  styleUrls: ["./admin-practice-dictation.component.scss"],
})
@Injectable({
  providedIn: "root",
})
export class AdminPracticeDictationComponent implements OnInit {
  resultJsonObj: any = {};

  loggedInUserId: string;
  loggedInUserRoleId: any;
  demographyUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  childRoute: string;
  documentsList: any;
  patientName: any;
  showActionCommentModal = false;
  httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
  httpOptions = { headers: this.httpHeaders };
  showLoader = false;
  message = Message;
  initiatedBy: any;
  admins: any = [];
  userRoleVal: any = 0;
  selectedAdminForComment: any;
  selectedPhysicianForComment: any;
  showAllData = 1;

  dictationList: any = [];
  sharedDictationList: any = [];
  selectedComment: any = {};
  dtOptions: DataTables.Settings = {};
  patientTranscribeListFlag: boolean = false;
  showDataVal: any = 1;
  fetchedText: any = "";
  showEditsummaryModal: boolean = false;
  editSummaryFlag: boolean = false;
  commentId: any;
  showAiSummaryModal: boolean = false;
  showShareSummaryModal: boolean = false;
  isSummarySigned: any = 0;
  isSummaryEdited: any = 0;
  showSignedAiSummaryModal: boolean = false;

  practices: any;
  physicians1: any;
  tempPhysician01: any = [];
  physicianInfo1: any = "";
  tempPractice01: any = [];
  practiceInfo1: any = "";
  finalPhysician01: any = [];
  selectedPhysiciansIdList: any = [];
  shareVisitSummariesFlag: boolean = false;
  isDelegating: any = 0;
  delegateId: any = 0;
  physicianName: any;
  showAiDictationModalPopup: boolean = false;
  chatBotVisible: boolean = false;
  patientId: string = ""; // Assuming this is where you store the patientId
  isPopUp: boolean = false; // Assuming this is a boolean value for isPopUp
  propValue: any;
  transcribeResponse: any;
  private transcribeApiUrl = "https://openaitimesheet.azurewebsites.net/transcribe";
  responseStatus: string | null = null;
  // responseStatus: 'pending' | 'success' | 'error' = 'pending';
  commentStatus: any | null = null;
  transStatus: string | null = null ;
  responseMessage: any;
  private isDestroyed = false;
  private pollingSubscription: Subscription | undefined;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    public userService: UserService,
    public datepipe: DatePipe,
    private textService: TextService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private location: Location,
    private httpClient: HttpClient,    
  ) 
  {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
    this.demographyUserId = this.activatedRoute.snapshot.paramMap.get('demographyUserId');
    this.initiatedBy = localStorage.getItem('loggedInUserName');
    this.isDelegating = localStorage.getItem("isDelegating") ? localStorage.getItem("isDelegating") : 0;
    this.delegateId = localStorage.getItem("delegateId") ? localStorage.getItem("delegateId") : 0;
    this.physicianName = localStorage.getItem('loggedInUserName');
  } 
  
  ngOnInit() {
    let empName = localStorage.getItem("loggedInUserName");
    const currentDateTime = format(new Date(), "yyyyMMdd_HHmmss");
    const fileName = `${empName}_${currentDateTime}.mp3`;   
    this.responseStatus = sessionStorage.getItem("transcribeStatus");  
    console.log("transcribeStatus : ", this.responseStatus);    
    this.commentStatus = sessionStorage.getItem("commentId");   
    const isPollingActive = sessionStorage.getItem("pollingActive");
    if (this.responseStatus === 'pending' && isPollingActive === 'true'  && this.commentStatus) {
      this.startPolling();
    } 
    const transcriptionInProgress = sessionStorage.getItem('transcriptionInProgress');
    if (transcriptionInProgress) {
        const { recordingUrl, commentId } = JSON.parse(transcriptionInProgress);
        this.regenerateTranscript(recordingUrl, commentId);
    }  
    // Request notification permission on component initialization
    this.requestNotificationPermission()
      .then(() => {
        this.showTranscriptionStatusNotification();
      })
      .catch((error) => {
        console.error("Notification permission error:", error);
      });  

    if (this.loggedInUserRoleId == 4) {
      this.findDictations();
      this.showData(1);
    } else {
      this.showData(2);
    }
    this.findAdminList();
    this.findUserDetails(this.demographyUserId);
    this.dtOptions = {
      order: [],
    };
    this.getUserPractices();
    this.propValue = { isPopUp: true, patientId: this.demographyUserId };

    window.addEventListener("transcribeStatusUpdate", (event: any) => {
      const detail = event.detail;  
      this.zone.run(() => {
        this.updateTranscribeStatus(detail);
        this.handleTranscribeStatusUpdate(detail);
      });
    });    
  }
 
  startPolling() {
    // Mark polling as active
    sessionStorage.setItem("pollingActive", 'true');  
    this.pollingSubscription = interval(5000) // Poll every 5 seconds
      .pipe(
        switchMap(() => this.httpClient.get(`https://openaitimesheet.azurewebsites.net/transcribe`).pipe(
          catchError((error) => {
            console.error("API call failed with error:", error);
            this.zone.run(() => {
              sessionStorage.setItem("transcribeStatus", 'error');
              sessionStorage.setItem("pollingActive", 'false'); // Stop polling
              this.responseStatus = 'error';
              this.stopPolling();
            });
            return EMPTY;
          })
        ))
      )
      .subscribe((status: any) => {
        this.zone.run(() => {
          if (status !== 'pending') {
            sessionStorage.setItem("transcribeStatus", status);
            sessionStorage.setItem("pollingActive", 'false'); // Stop polling
            this.responseStatus = status;
            this.stopPolling();
            this.showTranscriptionStatusNotification();
          }
        });
      });
  }
  
  stopPolling() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
    sessionStorage.setItem("pollingActive", 'false'); // Mark polling as stopped
  }

  updateTranscribeStatus(event: any) {
    const { status } = event;
    const {message } =event;        
    this.zone.run(() => {
      this.responseStatus = status;
      this.responseMessage = message;
      sessionStorage.setItem("transcribeStatus", status);
      this.showTranscriptionStatusNotification();
      // Remove responseStatus from sessionStorage after updating the UI
      setTimeout(() => {
      sessionStorage.removeItem("transcribeStatus");      
      }, 0);      
    });
  }
  
  handleTranscribeStatusUpdate(event: any): void {
    //from gpt
    const { status } = event;
    const {message } = event;    
    this.zone.run(() => {
      this.responseStatus = status;
      this.responseMessage = message;
      sessionStorage.setItem("transcribeStatus", status);
      sessionStorage.setItem("commentStatus", this.commentId);
      // this.updateRecordStatus(this.commentId,status)
      this.showTranscriptionStatusNotification();      
      setTimeout(() => {
      sessionStorage.removeItem("transcribeStatus");
      sessionStorage.removeItem("commentStatus");
      }, 0);      
    });    
  }
  showTranscriptionStatusNotification(): void {
    this.showNotification(this.responseStatus, this.responseMessage);    
  } 
  showNotification(status: string, message: string) {
      if (!("Notification" in window)) {
        return;
      }
      if (Notification.permission === "granted") {
        new Notification("Transcription Status", { body: message });
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            new Notification("Transcription Status", { body: message });
          }
        });
      }
      Swal.fire({
        title: status === "success" ? "Please Confirm" : "Error",
        text: message,
        // icon: status === "success" ? "success" : "error",
        confirmButtonText: "OK"
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
  }
  requestNotificationPermission(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!("Notification" in window)) {
        reject("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        // resolve();
        return Promise.resolve(Notification.permission);
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {           
            return Promise.resolve(permission);
          } else {            
            return Promise.reject("Notification permission denied");
          }
        });
      } else {        
        return Promise.reject("Notification permission denied");
      }
    });
  } 
 
  findUserDetails(demographicUserId) {
    const finalData = {
      id: demographicUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientName = this.userService.getUS_FromatName(
            apiResponse.commandResult.data.userInfo.FullName
          );
          this.showLoader = false;
        },
        (err) => {
          // console.error(err);
        }
      );
  }

  async findDictations() {
    this.responseStatus = sessionStorage.getItem("transcribeStatus");
    this.commentStatus = sessionStorage.getItem("commentId");
    //alert(this.responseStatus)
    this.dictationList = [];
    this.sharedDictationList = [];
    this.shareVisitSummariesFlag = false;
    const finalData = {
      userId: this.loggedInUserId,
      patientId: this.demographyUserId,
      companySlug: ConstantHelper.COMPANY_SLUG,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.findDictationFiles,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.Dictations.length > 0) {
            this.dictationList = res.commandResult.data.Dictations;
            this.showLoader = false;
            this.patientTranscribeListFlag = true;
          }
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
    this.showLoader = false;
  }

  openReplyModal(commentId) {
    this.selectedComment = this.dictationList.find(
      (e) => e.CommentId == commentId
    );
  }

  openActionCommentModal() {
    this.showActionCommentModal = true;
  }

  closeActionCommentModal() {
    this.showActionCommentModal = false;
  }

  findAdminList() {
    this.admins = [];

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
      userRoleVal: 1,
    };
    this.http
      .post(
        // this.appConfig.getPhpUrl() + ApiHelper.php_getAdminUsersListApi,
        this.appConfig.getLambdaUrl06() + ApiHelper.getAdminUsersList,
        finalData,

        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          this.admins = this.resultJsonObj.commandResult.data.adminUsers;
        },
        (err) => {}
      );
  }

  showData(value: any) {
    this.showDataVal = value;
    if (value == 2) {
      this.findSharedDictationSummaries();
    } else {
      this.findDictations();
    }
  }

  async goToDemographics() {
    // this.router.navigate([
    //   "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
    // ]);
    // // }
    this.location.back();
  }

  openMp3FIle(recordingUrl: string): void {
    if (recordingUrl) {
      window.open(recordingUrl, "_blank");
    } else {
      console.error("Recording URL is not available.");
    }
  }

  openTranscript(TranscriptUrl: string): void {
    if (TranscriptUrl) {
      window.open(TranscriptUrl, "_blank");
    } else {
      console.error("Transcript URL is not available.");
    }
  }

  openSummary(SummaryUrl: string): void {
    if (SummaryUrl) {
      window.open(SummaryUrl, "_blank");
    } else {
      console.error("Summary URL is not available.");
    }
  }

  encodeFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result.split(",")[1]);
        } else {
          reject(new Error("FileReader returned a non-string result"));
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };  

  

  async regenerateTranscript(recordingUrl: string, commentId: any) {
    try {
        sessionStorage.setItem('transcribeStatus', 'pending');
        // Save the ongoing process data in sessionStorage
        sessionStorage.setItem('transcriptionInProgress', JSON.stringify({ recordingUrl, commentId }));
        
        const response = await fetch(recordingUrl);
        const blobData = await response.blob();
        const formData = new FormData();
        formData.append("audio", blobData, "audio.mp3");

        if (this.transcribeApiUrl) {
            this.updateRecordStatus(commentId, 'pending');
            const apiResponse = await this.http.post<any>(this.transcribeApiUrl, formData).toPromise();

            if (apiResponse) {
                const { original_text_content, summary } = apiResponse;

                if (original_text_content && summary) {
                    const originalTextBlob = new Blob([original_text_content], { type: "text/plain" });
                    const summaryBlob = new Blob([summary], { type: "text/plain" });

                    const transcriptFile = new File([originalTextBlob], `transcription.txt`, { type: "text/plain" });
                    const summaryFile = new File([summaryBlob], `summary.txt`, { type: "text/plain" });

                    const transcriptBase64 = await this.encodeFileToBase64(transcriptFile);
                    const summaryBase64 = await this.encodeFileToBase64(summaryFile);

                    const SecondJsonData = {
                        company_slug: ConstantHelper.COMPANY_SLUG,
                        physician_id: this.loggedInUserId,
                        patient_id: this.demographyUserId,
                        chat_id: "",
                        audioKey: { filename: "", content: "" },
                        transcriptKey: { filename: transcriptFile.name, content: transcriptBase64 },
                        summaryKey: { filename: summaryFile.name, content: summaryBase64 },
                    };

                    let sls06baseurl = environment.lambdaApiUrl_06;
                    let apiurlphp = sls06baseurl + ApiHelper.uploadRecording;
                    let secondResult = await axios.post(`${apiurlphp}`, SecondJsonData);

                    if (secondResult.data && secondResult.data.commandResult && secondResult.data.commandResult.data) {
                        let secondUrl = secondResult.data.commandResult.data;

                        const updateDictationUrls = {
                            commentId: commentId,
                            transcriptUrl: secondUrl.transcriptUrl,
                            summaryUrl: secondUrl.summaryUrl,
                        };

                        let updateApiUrl = environment.lambdaApiUrl_05 + ApiHelper.updateComment;
                        await axios.post(`${updateApiUrl}`, updateDictationUrls);
                        this.updateRecordStatus(commentId, 'success');
                        window.dispatchEvent(new CustomEvent('transcribeStatusUpdate', {
                            detail: {
                                status: 'success',
                                transcriptUrl: secondUrl.transcriptUrl,
                                message: 'Transcribe is ready'
                            }
                        }));
                    } else {
                        this.handleError(commentId, "Invalid response structure from second API call", secondResult);
                    }
                } else {
                    this.handleError(commentId, "Missing original_text_content or summary in API response", apiResponse);
                }
            } else {
                this.handleError(commentId, "API response is null or undefined", apiResponse);
            }
        } else {
            this.handleError(commentId, "Transcribe API URL is not defined", null);
        }

        // Clear the session storage after successful completion
        sessionStorage.removeItem('transcriptionInProgress');
    } catch (error) {
        this.handleError(commentId, "Error during API call", error);
    }
}
  

  updateRecordStatus(commentId: any, status: 'pending' | 'success' | 'error') {
    const record = this.dictationList.find(record => record.CommentId === commentId);
    if (record) {
      record.Status = status;
      // this.zone.run(() => this.cdr.detectChanges()); // Trigger change detection
      this.zone.run(() => {
        if (!this.isDestroyed) {
          this.cdr.detectChanges();
        }
      });
    }
  }

  fetchText(fileUrl: any) {
    this.textService.getTextFromUrl(fileUrl).subscribe(
      (response: string) => {
        this.fetchedText = response;
      },
      (error) => {
        console.error("Error fetching text:", error);
      }
    );
  }

  openEditPopup() {
    this.showEditsummaryModal = true;
  }

  showAiSummaryModalPopup() {
    this.showAiSummaryModal = true;
  }

  showSignedAiSummaryModalPopup() {
    this.showSignedAiSummaryModal = true;
  }

  closeEditModal() {
    this.editSummaryFlag = false;
    this.showEditsummaryModal = false;
    this.showAiSummaryModal = false;
    this.showShareSummaryModal = false;
    this.showSignedAiSummaryModal = false;
   
  }

  closeAiDictationModal()
  {
    this.showAiDictationModalPopup = false;
    this.findDictations();
  }

  editSummaryFlagCondition() {
    this.editSummaryFlag = !this.editSummaryFlag;
  }

  setCommentId(commentId: any) {
    this.commentId = commentId;
  }

  generateTxtFile(text: string): Blob {
    const newText = `${text}`;
    const blob = new Blob([newText], { type: "text/plain" });
    return blob;
  }

  async uploadToAzure(value) {
    try {
      if (value == 0) {
        const txtBlob = this.generateTxtFile(this.fetchedText);
        if (!txtBlob) {
          throw new Error("Failed to generate text blob");
        }

        const alertStatus = await this.sweetAlertHelper.confirmPopUp(
          "Please Confirm",
          "Must sign prior to share"
        );
        if (alertStatus) {
          this.isSummaryEdited = 1;
          const editedBy = localStorage.getItem("loggedInUserName");
          const summaryFile = new File(
            [txtBlob],
            `summary_edited_by_${editedBy}.txt`,
            { type: "text/plain" }
          );
          const azureBody = new FormData();
          azureBody.append("fileKey", summaryFile);
          azureBody.append("loggedInUserId", this.loggedInUserId);
          const azureApiUrl =
            this.appConfig.getPhpUrl() + ApiHelper.php_uploadAzureFile;
          const res = await axios.post(azureApiUrl, azureBody, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          if (res.data) {
            const fileUrl = res.data.commandResult.data.fileUrl;
            await this.updateEditedSummaryUrl(fileUrl);
          }
        } else {
          return false;
        }
      } else if (value == 1) {
        const txtBlob = this.generateTxtFile(this.fetchedText);
        var txtBlobText = await (txtBlob as any).text();
        if (!txtBlob) {
          throw new Error("Failed to generate text blob");
        }

        const alertStatus = await this.sweetAlertHelper.confirmPopUp(
          "Please Confirm",
          "I previewed this document electronically and signed. Please note, one electronically signed, this will be read only."
        );
        if (alertStatus) {
          this.isSummaryEdited = 1;
          this.isSummarySigned = 1;
          const today = new Date();
          const formattedDate = today.toLocaleString();
          const signedBy = localStorage.getItem("loggedInUserName");
          const finalText = `${txtBlobText}\n\n\nThis is electronically signed document\nSigned By: Dr. ${signedBy}\nDate: ${formattedDate}`;
          const summaryFile = new File(
            [finalText],
            `summary_signed_by_${signedBy}.txt`,
            { type: "text/plain" }
          );
          const azureBody = new FormData();
          azureBody.append("fileKey", summaryFile);
          azureBody.append("loggedInUserId", this.loggedInUserId);
          const azureApiUrl =
            this.appConfig.getPhpUrl() + ApiHelper.php_uploadAzureFile;
          const res = await axios.post(azureApiUrl, azureBody, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          if (res.data) {
            const fileUrl = res.data.commandResult.data.fileUrl;
            await this.updateEditedSummaryUrl(fileUrl);
          }
        } else {
          return false;
        }
      }
    } catch (err) {
      console.error("Error uploading to Azure:", err);
    }
  }

  async updateEditedSummaryUrl(fileUrl) {
    const finalData = {
      commentId: this.commentId,
      editedSummaryUrl: fileUrl,
      isSummaryEdited: this.isSummaryEdited,
      isSummarySigned: this.isSummarySigned,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.updateDictationSummary,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
          this.showEditsummaryModal = false;
          window.location.reload();
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  findSharedDictationSummaries() {
    this.sharedDictationList = [];
    this.dictationList = [];
    this.patientTranscribeListFlag = false;
    let finalData: { receivingPhysicianId: string; patientId: string };
    if (this.isDelegating == 1) {
      finalData = {
        receivingPhysicianId: this.delegateId,
        patientId: this.demographyUserId,
      };
    } else {
      finalData = {
        receivingPhysicianId: localStorage.getItem("loggedInUserId"),
        patientId: this.demographyUserId,
      };
    }

    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
          ApiHelper.fetchPhysicianSharedDictationSummary,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.SummaryDetails.length > 0) {
            this.sharedDictationList = res.commandResult.data.SummaryDetails;
            this.showLoader = false;
            this.shareVisitSummariesFlag = true;
          }
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
    this.showLoader = false;
  }

  // SHare with physicianas code

  openShareSummaryModal() {
    this.showShareSummaryModal = true;
  }

  onChangePractice(companyUrl) {
    const reqData = {
      company_slug: companyUrl,
      loggedInUserId: this.demographyUserId,
    };

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserDoctorsByPracticeApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.physicians1 = apiResponse.commandResult.data.companiesList;
        },
        (err) => {}
      );
  }

  getUserPractices() {
    const finalData = {
      loggedInUserId: this.demographyUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserPracticeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.practices = apiResponse.commandResult.data.companiesList;
          this.showLoader = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  async listData() {
    if (this.physicianInfo1.length <= 0) {
      await this.sweetAlertHelper.alertPopUp(
        "Please select practice or physician"
      );
      return false;
    }
    if (this.physicianInfo1 == "all") {
      this.tempPractice01.push(
        this.practices.find((x) => x.company_url == this.practiceInfo1)
      );
    } else {
      this.physicianInfo1.forEach((element) => {
        const existingPhysician = this.finalPhysician01.find(
          (x) => x.physician_id == element
        );
        if (!existingPhysician) {
          const selectedPhysician = this.physicians1.find(
            (x) => x.physician_id == element
          );
          if (selectedPhysician) {
            this.finalPhysician01.push(selectedPhysician);
            this.tempPhysician01.push(selectedPhysician);

            this.selectedPhysiciansIdList.push(selectedPhysician.physician_id);
          }
        }
      });
    }
  }

  onChangePhysician(value, j) {
    if (value == "all" && j == 0) {
    }
    if (value == "all" && j == 1) {
    }
    if (value == "all" && j == 2) {
    }
    if (value == "all" && j == 3) {
    }
    if (value == "all" && j == 4) {
    }
  }

  shareSummaryWithPhysician() {
    const finalData = {
      patientId: this.demographyUserId,
      sendingPhysicianId: localStorage.getItem("loggedInUserId"),
      receivingPhysicianId: this.selectedPhysiciansIdList,
      dictationSummaryId: this.commentId,
    };


    // return
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl05() +
          ApiHelper.postPhysicianSharedDictationSummary,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.showShareSummaryModal = false;
            this.selectedPhysiciansIdList = [];
          }
          this.showLoader = false;
        },
        (err) => {
          // console.error(err);
        }
      );
  }

  showAiDictationModal() {
    this.showAiDictationModalPopup = true;
    this.chatBotVisible = true;
    this.patientId = this.demographyUserId; // Set patientId based on your logic
    this.isPopUp = true; // Set isPopUp to true
  }

  async openDictationWindow() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.dictationConfirmComp.dictationModal
    );
    if (alertStatus) {
      this.router.navigate(
        ["/" + this.childRoute + "/ahsgpt/" + this.loggedInUserId],
        { queryParams: { isPopUp: true, patientId: this.demographyUserId } }
      );
    }
  }

  
  private handleError(commentId: any, errorMessage: string, errorDetails: any) {
    console.error(errorMessage, errorDetails);
    this.updateRecordStatus(commentId, 'error');
    window.dispatchEvent(new CustomEvent('transcribeStatusUpdate', {
        detail: {
            status: '400 bad request',
            message: 'Unable to understand audio... Failed to generate Transcribe'
        }
    }));
    sessionStorage.removeItem('transcriptionInProgress');
}


  ngOnDestroy() {
    this.isDestroyed = true;
    // Remove the event listener when the component is destroyed
    window.removeEventListener("transcribeStatusUpdate", (event: any) => {});
  }



  //////////////////////////////////////////////////////////////////////////////////////////////////
}
