import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';

import {ApiHelper} from './../_helpers/api.helper';
import {CommonHelper} from './../_helpers/common.helper';
import {ConstantHelper} from './../_helpers/constant.helper';
import {AppConfig} from './../../app/app.config';
import {UserService} from '../_services/user.service';
import {SweetAlertHelper} from '../_helpers/sweet.alert.helper';
import {Message} from '../_locale/message';
import {Location} from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  loggedInUserName: any;
  demographicId: string;
  loggedInUserId: any;
  loggedInUserRoleId: any;
  showUserLink = false;
  resultJsonObj: any = {};
  childRoute: string;
  showLogououtModal = false;
  showLoader = false;
  doctorToDoctorEmailRespondCount: any;
  doctorToDoctorEmailPendingCount: any;
  patientSecondOpinionRespondCount: any;
  patientSecondOpinionPendingCount: any;
  physicianSecondOpinionRespondCount: any;
  physicianSecondOpinionPendingCount: any;
  expertSecondOpinionRespondCount: any;
  expertSecondOpinionPendingCount: any;
  totalPendingCount: any;
  isCOE: any;
  logoutCount = 0;
  popupCount = 0;
  COECityState: any;
  isPrimary = 0;
  companySlug: any;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  message = Message;
  showWhatCanIDo = false;
  dashBoardItems = false;
  physicianProfileItems = false;
  administratorsListItems = false;
  transferReportItems = false;
  adminActivityItems = false;
  adminActionPendingCount: any;
  adminCommentList: any = [];
  isDelegating = localStorage.getItem('isDelegating') ? localStorage.getItem('isDelegating') : 0;
  delegateId = localStorage.getItem('delegateId') ? localStorage.getItem('delegateId') : 0;
  invitePatientLogsList: any;
  patientInvitedPendingCount: any;
  patientRegisteredCount: any;
  designation: any;
  practiceDocSignCount: any;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    public userService: UserService,
    private location: Location
  ) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.findUserDetails();
    this.loggedInUserName = localStorage.getItem('loggedInUserName')
      ? localStorage.getItem('loggedInUserName')
      : localStorage.getItem('loggedInUserEmail');
    this.loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.findUserDetails();
    if (this.isDelegating == 0) {
      this.pendingItems(this.loggedInUserId, 4);
      this.pendingItems(this.loggedInUserId, 3);
      this.pendingItems(this.loggedInUserId, 2);
      this.pendingItems(this.loggedInUserId, 1);
      this.findInvitePatientLogs(this.loggedInUserId)
    }
    this.doctorToDoctorEmailPendingCount = 0;
    this.doctorToDoctorEmailRespondCount = 0;
    this.patientSecondOpinionRespondCount = 0;
    this.patientSecondOpinionPendingCount = 0;
    this.physicianSecondOpinionRespondCount = 0;
    this.physicianSecondOpinionPendingCount = 0;
    this.expertSecondOpinionRespondCount = 0;
    this.expertSecondOpinionPendingCount = 0;
    this.adminActionPendingCount = 0;
    this.patientInvitedPendingCount = 0;
    this.patientRegisteredCount = 0;

  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    $('.dropmenu-link').hover(function() {
      const isHovered = $(this).is(':hover');
      if (isHovered) {
        $(this).children('ul').stop().slideDown(300);
      } else {
        $(this).children('ul').stop().slideUp(300);
      }
    });
    if(this.loggedInUserRoleId == 4){
      this.findDoctorByUserId(this.loggedInUserId);
    }
    this.companySlug = ConstantHelper.COMPANY_SLUG;
    this.findUserDetails();
    this.getCurrentUrl();
    this.showItemList();
    this.getAdminCommentsList();
    localStorage.setItem('currentURL', this.location.path());
    localStorage.setItem('baseURL', window.location.origin);
    this.fnPracticeDocSignCount()

  }

  async logout() {
    if (
      ConstantHelper.COMPANY_SLUG == 'other' &&
      this.loggedInUserRoleId == '4' && this.popupCount < 3
    ) {
      // this.showLoader = true;
      this.showLogououtModal = true;
    } else {
      // if (
      //   confirm("PLEASE CONFIRM: Are you sure, you want to close your session?")
      // )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.headerComp.logout
      );

      if (alertStatus) {
        {
          localStorage.clear();
          window.open(this.userService.parentSiteUrl() + '/', '_self');
        }
      }
    }
  }


  async sendEmailRequest() {
    // localStorage.clear();
    // window.open(this.userService.parentSiteUrl() + "/", "_self");

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.headerComp.SendLogoutEmail1);
    if (alertStatus) {
      const finalData = {
        company_slug: ConstantHelper.COMPANY_SLUG,
        loggedInUserId: this.loggedInUserId,
        emailRequest: 1,
        popupCount: this.popupCount
      };
      this.showLoader = true;
      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_logoutMailApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse) => {
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.headerComp.sendLogoutEmail);
            if (alertStatus) {
              this.dologout();
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async justLogout() {
    this.showLogououtModal = false;
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.headerComp.logout);
    if (alertStatus) {
      const finalData = {
        company_slug: ConstantHelper.COMPANY_SLUG,
        loggedInUserId: this.loggedInUserId,
        emailRequest: 0,
        popupCount: this.popupCount
      };
      this.showLoader = true;
      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_logoutMailApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse) => {
            this.showLoader = false;
            this.dologout();
          },
          (err) => {
            // console.log(err);
          }
        );
    } else {
      this.showLogououtModal = true;
    }

  }


  handleLogout() {
    if (this.companySlug == 'other' && this.popupCount < 3 && this.popupCount >= 0) {
      this.logout();
    } else {
      this.justLogout();
    }
  }

  dologout() {
    localStorage.clear();
    window.open(this.userService.parentSiteUrl() + '/', '_self');
  }

  async goToMainSite() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to your Home Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );

    {
      window.open(
        this.userService.parentSiteUrl() + '/dashboard/' + this.loggedInUserId,
        '_self'
      );
    }
  }

  async goToDashboard() {
    this.resetDelegation();
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to go to Home page?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );

    if (alertStatus) {
      this.router.navigate(['/' + this.childRoute + '/dashboard/' + this.loggedInUserId]);
    }
  }

  async goToProfile() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to review your Log In Profile?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.headerComp.goToProfile
    );
    if (alertStatus) {
      this.router.navigate(['/user-profile/' + this.loggedInUserId]);
    }
  }

  async goToRegisteredDoctors() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to go to Home page?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    {
      window.open(
        this.userService.parentSiteUrl() +
        '/registered-doctors/' +
        this.loggedInUserId,
        '_self'
      );
    }
  }

  async goToFaq() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.headerComp.goToFaq
    ); // if (confirm("PLEASE CONFIRM: Are you sure you want to go to faqs?"))

    if (alertStatus) {
      this.router.navigate(['/user-faq/' + this.loggedInUserId]);
    }
  }

  findUserDetails() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('userd d', apiResponse);
          this.logoutCount = this.resultJsonObj.commandResult.data.userInfo.LogoutCount;
          this.popupCount = this.resultJsonObj.commandResult.data.userInfo.PopupCount;
          this.isPrimary = this.resultJsonObj.commandResult.data.userInfo.IsPrimary;
          this.isCOE = this.resultJsonObj.commandResult.data.userInfo.coe_status;
          this.loggedInUserName = this.resultJsonObj.commandResult.data.userInfo.FullName;
          this.designation = this.resultJsonObj.commandResult.data.userInfo.Designation;
          localStorage.setItem(
            'loggedInUserName',
            this.resultJsonObj.commandResult.data.userInfo.FullName
          );
          localStorage.setItem(
            'loggedInUserEmail',
            this.resultJsonObj.commandResult.data.userInfo.Email
          );
          localStorage.setItem(
            'loggedInUserRoleId',
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId
          );
          this.loggedInUserRoleId =
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId;
          localStorage.setItem('loggedInUserComapnyId', '1');
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToContactUs() {
    this.router.navigate([
      '/' + this.childRoute + '/contact-us/' + this.loggedInUserId,
    ]);
  }

  goToContacChangePassword() {
    this.router.navigate([
      '/' + this.childRoute + '/change-password/' + this.loggedInUserId,
    ]);
  }

  doctorToDoctorEmail() {
    this.router.navigate([
      '/' + this.childRoute + '/transfer-log/' + this.loggedInUserId + '/' + 4,
    ]);
  }

  patientSecondOpinion() {
    this.router.navigate([
      '/' + this.childRoute + '/transfer-log/' + this.loggedInUserId + '/' + 3,
    ]);
  }

  physicianSecondOpinion() {
    this.router.navigate([
      '/' + this.childRoute + '/transfer-log/' + this.loggedInUserId + '/' + 2,
    ]);
  }

  expertSecondOpinion() {
    this.router.navigate([
      '/' + this.childRoute + '/transfer-log/' + this.loggedInUserId + '/' + 1,
    ]);
  }

  pendingItems(userId, logType) {
    const finalData = {
      userId,
      logType,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findOpinionLogApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          for (let i = 0; i < res.commandResult.data.logs.length; i++) {
            if (logType == 4) {
              if (res.commandResult.data.logs[i].ResponseDateTime === '') {
                this.doctorToDoctorEmailPendingCount++;
                localStorage.setItem('doctorToDoctorEmailPendingCount', this.doctorToDoctorEmailPendingCount);
              } else {
                this.doctorToDoctorEmailRespondCount++;
                localStorage.setItem('doctorToDoctorEmailRespondCount', this.doctorToDoctorEmailRespondCount);
              }
            } else if (logType == 3) {
              if (res.commandResult.data.logs[i].ResponseDateTime === '') {
                this.patientSecondOpinionPendingCount++;
                localStorage.setItem('patientSecondOpinionPendingCount', this.patientSecondOpinionPendingCount);
              } else {
                this.patientSecondOpinionRespondCount++;
                localStorage.setItem('patientSecondOpinionRespondCount', this.patientSecondOpinionRespondCount);
              }
            } else if (logType == 2) {
              if (res.commandResult.data.logs[i].ResponseDateTime === '') {
                this.physicianSecondOpinionPendingCount++;
                localStorage.setItem('physicianSecondOpinionPendingCount', this.physicianSecondOpinionPendingCount);
              } else {
                this.physicianSecondOpinionRespondCount++;
                localStorage.setItem('physicianSecondOpinionRespondCount', this.physicianSecondOpinionRespondCount);
              }
            } else if (logType == 1) {
              if (res.commandResult.data.logs[i].ResponseDateTime === '') {
                this.expertSecondOpinionPendingCount++;
                localStorage.setItem('expertSecondOpinionPendingCount', this.expertSecondOpinionPendingCount);
              } else {
                this.expertSecondOpinionRespondCount++;
                localStorage.setItem('expertSecondOpinionRespondCount', this.expertSecondOpinionRespondCount);
              }
            }
          }
          this.totalPendingCount =
            +this.doctorToDoctorEmailPendingCount +
            +this.patientSecondOpinionPendingCount +
            +this.physicianSecondOpinionPendingCount +
            +this.expertSecondOpinionPendingCount +
            +this.practiceDocSignCount + 
            +this.patientInvitedPendingCount;
          },
        (err) => {
          // console.log(err);
        }
      );
  }

  findDoctorByUserId(userId) {
    const finalData = {
      userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findDoctorByUserIdApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.status === 1) {
            this.COECityState = apiResponse.commandResult.data.npiInfo.PhysicianCity + ', ' + apiResponse.commandResult.data.npiInfo.PhysicianPrimaryState;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  closeLogoutModal() {
    this.showLogououtModal = false;
  }

  openPhysicianHelpDocument() {
    window.open(
      'https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Physician_Site_Instructions.pdf',
      '_blank'
    );
  }

  openAdminHelpDocument() {
    window.open(
      'https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Admin_Site_Instructions.pdf',
      '_blank'
    );
  }

  async goToHomePage() {
    this.resetDelegation();
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      if (alertStatus) {
        this.router.navigate([
          '/' + this.childRoute + '/dashboard/' + this.loggedInUserId,
        ]);
      }
    }
  }

  getCurrentUrl(): string {
    return this.location.path();
  }


  showItemList() {
    if (this.getCurrentUrl().includes('/doctor/dashboard/')) {
      this.showWhatCanIDo = true;
      this.dashBoardItems = !this.dashBoardItems;
    } else if (this.getCurrentUrl().includes('/doctor/doctor-profile/')) {
      this.showWhatCanIDo = true;
      this.physicianProfileItems = !this.physicianProfileItems;
    } else if (this.getCurrentUrl().includes('/doctor/users/')) {
      this.showWhatCanIDo = true;
      this.administratorsListItems = !this.administratorsListItems;
    } else if (this.getCurrentUrl().includes('/doctor/transfer-reports/')) {
      this.showWhatCanIDo = true;
      this.transferReportItems = !this.transferReportItems;
    } else if (this.getCurrentUrl().includes('/doctor/delegation-log/')) {
      this.showWhatCanIDo = true;
      this.adminActivityItems = !this.adminActivityItems;
    }
  }


  goToCommentsAndActions() {
    this.router.navigate([
      '/admin/comments/' + this.loggedInUserId + '/' + this.adminCommentList.patient_id,
    ]);
  }

  getAdminCommentsList() {
    const finalData = {
      company_slug: this.companySlug,
      admin_id: this.loggedInUserId,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getLastCommentAdmin,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (Object.keys(apiResponse.commandResult.data.comment).length != 0) {
            this.adminCommentList = await apiResponse.commandResult.data.comment;
            this.adminActionPendingCount++;
          }

          if (this.adminActionPendingCount == 0) {
            this.getAdminCommentListWithAllAdminId();
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  getAdminCommentListWithAllAdminId() {
    const finalData = {
      company_slug: this.companySlug,
      admin_id: 0,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getLastCommentAdmin,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (Object.keys(apiResponse.commandResult.data.comment).length != 0) {
            this.adminCommentList = await apiResponse.commandResult.data.comment;
            this.adminActionPendingCount++;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  resetDelegation() {
    localStorage.removeItem("delegateId");
    localStorage.removeItem("isDelegating");
  }

  async findInvitePatientLogs(userId) {
    const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    const options = { headers: httpHeaders };

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.patientInviteLog,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse: any) => {
          this.showLoader = false;
          this.invitePatientLogsList = apiResponse.commandResult.data.logsList;
          const patientInvitedPendingCount = this.invitePatientLogsList.filter(item => item.IsRegistered == 0)
          const patientRegisteredCount = this.invitePatientLogsList.filter(item => item.IsRegistered == 1)
          this.patientInvitedPendingCount = patientInvitedPendingCount.length
          this.patientRegisteredCount = patientRegisteredCount.length
        },
        (err) => {}
      );
  }

  goToPatientInviteList(){
    this.router.navigate([
      '/' + this.childRoute + '/invite-patient/' + this.loggedInUserId,
    ]);
  }

  fnPracticeDocSignCount () {
    const finalData = {
      loggedInUserId: this.loggedInUserId
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.findPracticeDocsCountByPhysicianId,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if(apiResponse.commandResult.data.totalCount[0].Count > 0){
            this.practiceDocSignCount = apiResponse.commandResult.data.totalCount[0].Count
          } else {
            this.practiceDocSignCount = 0
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToPatientList(){this.router.navigate(['/' + this.childRoute + '/demographics/' + this.loggedInUserId + "/0",]);}

}
