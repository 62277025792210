import {Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {SignaturePad} from 'angular2-signaturepad';
import {FormBuilder} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';


import {AppConfig} from './../../../../app/app.config';
import { Message } from 'src/app/_locale/message';
import {SweetAlertHelper} from './../../../_helpers/sweet.alert.helper';
import {CommonHelper} from './../../../_helpers/common.helper';
import {ConstantHelper} from './../../../_helpers/constant.helper';
import {ApiHelper} from './../../../_helpers/api.helper';
import { DataService } from 'src/app/_helpers/data.service.helper';


@Component({
  selector: 'app-sign-hipaa',
  templateUrl: './sign-hipaa.component.html',
  styleUrls: ['./sign-hipaa.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class SignHipaaComponent implements OnInit {

  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  clean = '0';
  @ViewChild(SignaturePad, {static: false}) signaturePad: SignaturePad;
  @ViewChild('canvasArea', {static: true}) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: object = {
    minWidth: 1,
    canvasWidth: window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  showLoader = false;
  message = Message;
  jsonData: any;
  demogId: any;
  childRoute: string;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private dataService: DataService,
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.clean = this.activatedRoute.snapshot.paramMap.get('clean');
    this.loggedInUserId = localStorage.getItem('delegateId') ? localStorage.getItem('delegateId') : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    

    if (this.clean === '1') {
      this.cleanTempDocs();
    }

    this.jsonData = this.dataService.getData();
    console.log("Ayyub", this.jsonData)
    // this.getDemographicId(this.loggedInUserId);
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }


  async clearCanvas() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.njComp.clearCanvas);
    if (alertStatus) {
      this.signaturePad.clear();
    }
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log("begin drawing");
  }

  editDemographicForm4() {
    this.router.navigate(['/edit-demographic-4/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')]);
  }

  goToWaiverDisclaimerForm() {
    this.router.navigate(['/waiverdisclaimerform/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')]);
  }

  goToDemographicForm() {
    this.router.navigate(['/demographicsform/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')]);
  }


  async goToHomePage() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, 'Are you sure you want to go to Home Page?');
    if (alertStatus) {
      this.router.navigate([this.childRoute + '/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')]);
    }
  }

  cleanTempDocs() {
    const finalData = {
      id: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_cleanTempDocsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
        },
        (err) => {
          // console.log(err);
        }
      );
  }
  getDemographicId(userId) {
    const finalData = {
      loggedInUserId: userId,
    };
    // this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getDemographicId_phy,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('findUserDetails', apiResponse)
          if (apiResponse.commandResult.status == 1) {
            this.demogId =apiResponse.commandResult.data.demographicId[0].id; 
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }

      );
      
  }
   
  async saveCanvas() {
    if (this.signaturePad.isEmpty() === true) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp('Please Sign!');
    } else {
      const alertStatus = await this.sweetAlertHelper.alertPopUp('Are you sure you want to update this profile? Your current HIPAA, waiver and disclaimer forms will be updated and re-signed');
      if (alertStatus) {
        this.showLoader = true;
        
        if (localStorage.getItem('loggedInUserRoleId') == '1') {
          const finalData = {
            loggedInUserId: localStorage.getItem('loggedInUserId'),
            signatureBase64: this.signaturePad.toDataURL(),
            company_slug: null,
            userRoleId: localStorage.getItem('loggedInUserRoleId'),
          };

          this.dataService.setData(finalData);

          // const finalData = this.dataService.getData();

          return this.http.post(
            this.appConfig.getLambdaUrl05() + ApiHelper.generateSignatureImagePhyAdmin,
            finalData,
            this.httpOptions
          )
          .subscribe(
            (apiResponse: any) => {

              this.dataService.setData({signatureUrl: apiResponse.commandResult.data.fileInfo.signatureUrl})
              
              this.http
                .post(
                  this.appConfig.getLambdaUrl06() + ApiHelper.generateDemographicAdmin,
                  this.dataService.getData(),
                  this.httpOptions
                )
                .subscribe(
                  (apiDemoResponse: any) => {
                    this.resultJsonObj = apiDemoResponse;
                    this.dataService.setData({docs: {demographicSheetUrl: this.resultJsonObj.commandResult.data.demographic_sheet_url, demographicSheetName: this.resultJsonObj.commandResult.data.demographic_sheet_name}})
                    this.http
                      .post(
                        this.appConfig.getLambdaUrl06() + ApiHelper.generateSignatureFilesPdfAdmin,
                        this.dataService.getData(),
                        this.httpOptions
                      )
                      .subscribe(
                        async (apiSignResponse: any) => {
                          this.showLoader = false;
                          this.resultJsonObj = apiSignResponse;
                          this.dataService.setData({docs: {...this.dataService.getData().docs, ahsHipaaFormUrl: this.resultJsonObj.commandResult.data.ahs_hipaa_form_url, ahsHipaaFormName: this.resultJsonObj.commandResult.data.ahs_hipaa_form_name, disclaimerUrl: this.resultJsonObj.commandResult.data.disclaimer_url, disclaimerName: this.resultJsonObj.commandResult.data.disclaimer_name}})
                          this.showLoader = false;
                          if ( this.resultJsonObj.commandResult.success === 1
                            
                          ) {
                            // alert("Habibi Jamie")
                            this.router.navigate([this.childRoute + '/review-doc/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')]);
                          } else {
                            await this.sweetAlertHelper.alertPopUp('Oops! An error has occurred; please try again');
                          }
                        },
                        (err) => {
                          // console.log(err);
                        }
                      );
                  },
                  (err) => {
                    // console.log(err);
                  }
                );
            },
            (err) => {
              // console.log(err);
            }
          );
          
        } else if (localStorage.getItem('loggedInUserRoleId') == '4') {
          const finalData = {
            loggedInUserId: localStorage.getItem('loggedInUserId'),
            signatureBase64: this.signaturePad.toDataURL(),
            company_slug: null,
            userRoleId: localStorage.getItem('loggedInUserRoleId'),
          };

          this.dataService.setData(finalData);

          return this.http.post(
            this.appConfig.getLambdaUrl05() + ApiHelper.generateSignatureImagePhyAdmin,
            finalData,
            this.httpOptions
          )
          .subscribe(
            (apiResponse: any) => {
              this.dataService.setData({signatureUrl: apiResponse.commandResult.data.fileInfo.signatureUrl})
              // return
              this.http
                .post(
                  // this.appConfig.getPhpUrl() + ApiHelper.php_generate_demographic_temp_Api,
                  this.appConfig.getLambdaUrl06() + ApiHelper.generateDemographicPhysician,
                  this.dataService.getData(),
                  this.httpOptions
                )
                .subscribe(
                  (apiDemoResponse: any) => {
                    this.resultJsonObj = apiDemoResponse;
                    this.dataService.setData({docs: {demographicSheetUrl: this.resultJsonObj.commandResult.data.demographic_sheet_url, demographicSheetName: this.resultJsonObj.commandResult.data.demographic_sheet_name}})
                    this.http
                      .post(
                        this.appConfig.getLambdaUrl06() + ApiHelper.generate_signature_files_pdf_phy,
                        this.dataService.getData(),
                        this.httpOptions
                      )
                      .subscribe(
                        async (apiSignResponse: any) => {
                          this.resultJsonObj = apiSignResponse;
                          this.showLoader = false;
                          this.dataService.setData({docs: {...this.dataService.getData().docs, ahsHipaaFormUrl: this.resultJsonObj.commandResult.data.ahs_hipaa_form_url, ahsHipaaFormName: this.resultJsonObj.commandResult.data.ahs_hipaa_form_name, disclaimerUrl: this.resultJsonObj.commandResult.data.disclaimer_url, disclaimerName: this.resultJsonObj.commandResult.data.disclaimer_name}})
                          //this.showLoader = false;
                          if ( this.resultJsonObj.commandResult.success === 1
                          ) {
                            this.showLoader = false;
                            this.router.navigate([this.childRoute + '/review-doc/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')]);
                          } else {
                            await this.sweetAlertHelper.alertPopUp('Oops! An error has occurred; please try again');
                          }
                          this.showLoader = false;
                        },
                        (err) => {
                          // console.log(err);
                        }
                      );
                  },
                  (err) => {
                    // console.log(err);
                  }
                );
            },
            (err) => {
              // console.log(err);
            }
          );
        }


      }
    }
  }


}
